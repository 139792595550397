import { useState } from 'react'
import useApi from './useApi'
import useData from './useData'
import { AppointmentEditSchemaType } from 'schemas/appointment-edit-schema'
import moment from 'moment'

export type AvailabilityRoom = {
  roomId: number
  roomTitle: string
  slots: {
    date: Date
    durations: number[]
  }[]
}

/**
 * Hook for finding availability throughout the app, only requires a search date to use
 */
const useAvailability = (date: string) => {
  const api = useApi()
  const data = useData()

  const [isCalculating, setIsCalculating] = useState(false)
  const [timeSlots, setTimeSlots] = useState<Date[]>([])
  const [availability, setAvailability] = useState<AvailabilityRoom[]>([])

  const selectedDate = moment(date)

  const businessStart = selectedDate.clone().hours(8).minutes(0).toDate()
  const businessEnd = selectedDate.clone().hours(21).minutes(0).toDate()

  const lunchStart = selectedDate.clone().hours(12).minutes(30).seconds(0).toDate()
  const lunchEnd =
    selectedDate.day() === 6
      ? selectedDate.clone().hours(13).minutes(0).seconds(0).toDate()
      : selectedDate.clone().hours(13).minutes(15).seconds(0).toDate()

  const getTimeSlots = (): Promise<Date[]> => {
    return new Promise(resolve => {
      const timeSlots: Date[] = []
      for (let i = businessStart; i < businessEnd; i.setMinutes(i.getMinutes() + 15)) {
        timeSlots.push(new Date(i))
      }
      setTimeSlots(timeSlots)
      resolve(timeSlots)
    })
  }

  /**
   * Get availability
   */
  const loadAvailability = async (appointment?: AppointmentEditSchemaType) => {
    setIsCalculating(true)

    const updatedTimeSlots = await getTimeSlots()

    const day = selectedDate.format('YYYY-MM-DD')
    const dayEnd = selectedDate.clone().add(1, 'day').format('YYYY-MM-DD')

    const appointments = await api.appointments.range(day, dayEnd) // all appointments for the day
    const rooms = await api.roomAvailability.range(day, day) // all available rooms for the day

    if (appointments.errors || rooms.errors) {
      // TODO: Do something with the errors
      setAvailability([])
    } else {
      const availability = rooms.data[0].roomIds
        .map(roomId => {
          const roomAvailability: AvailabilityRoom = {
            roomId: roomId,
            roomTitle: data.rooms.one(roomId)?.title || '',
            slots: [],
          }

          // All appointments for the day assigned to this room
          let activeAppointments = appointments.data.filter(
            _appointment =>
              _appointment.room.id === roomId && (_appointment.status.id === 1 || _appointment.status.id === 2)
          )

          if (appointment) {
            activeAppointments = [...activeAppointments].filter(
              _appointment => _appointment.id !== parseInt(appointment.id as string)
            )
          }

          updatedTimeSlots.forEach(timeSlot => {
            let durations = [15, 30, 45, 60]

            const _slot = moment(timeSlot)

            durations = durations.filter(duration => {
              const _durationStart = _slot.clone().toDate()
              const _durationEnd = _slot
                .clone()
                .minutes(_slot.minutes() + duration)
                .toDate()

              // Check if conflicts with any appointments
              const conflictingAppointments = [...activeAppointments].filter(_appointment => {
                return (
                  _durationStart < moment(_appointment.endDate).toDate() &&
                  _durationEnd > moment(_appointment.startDate).toDate()
                )
              })

              // Check if in conflicts with lunch or after work hours
              const conflictsWithLunchHours = _durationStart < lunchEnd && _durationEnd > lunchStart
              const conflictsWithBusinessHours = _durationEnd > businessEnd

              const ok = !conflictingAppointments.length && !conflictsWithLunchHours && !conflictsWithBusinessHours

              return ok
            })

            if (durations.length) {
              roomAvailability.slots.push({
                date: timeSlot,
                durations: durations,
              })
            }
          })
          return roomAvailability
        })
        .sort((roomA, roomB) => roomA.roomId - roomB.roomId)
      setAvailability(availability)
    }
    setIsCalculating(false)
  }

  return {
    isCalculating,
    timeSlots,
    availability,
    loadAvailability,
  }
}

export default useAvailability
