import useFetch from './useFetch'
import useHelpers from './useHelpers'
import { type User } from 'providers/AuthProvider'

/**
 * Namespaced functions for different areas of the API
 */
const useApi = () => {
  const { getRequest, postRequest, deleteRequest, putRequest } = useFetch()
  const helpers = useHelpers()

  const data = {
    all: async () => {
      return await getRequest<AppData>('api/data?entities[]=all')
    },
  }

  const roomAvailability = {
    date: async (date: string) => {
      return await getRequest<RoomAvailability[]>('api/availability/rooms', { date })
    },
    range: async (start: string, end: string) => {
      return await getRequest<RoomAvailability[]>('api/availability/rooms', { start, end })
    },
    update: async (data: RoomAvailability) => {
      return await postRequest<RoomAvailability>('api/availability/rooms', JSON.stringify(data))
    },
  }

  const nurseAvailability = {
    range: async (start: string, end: string) => {
      return await getRequest<NurseAvailability[]>('api/availability/nurses', { start, end })
    },
    update: async (data: NurseAvailability) => {
      return await postRequest<NurseAvailability>('api/availability/nurses', JSON.stringify(data))
    },
  }

  const appointments = {
    range: async (start: string, end: string, patientId: number | string | undefined = undefined) => {
      return await getRequest<Appointment[]>('api/appointment/list', { start, end, patientId })
    },
    one: async (id: number | string) => {
      return await getRequest<Appointment>(`api/appointment/${Number(id)}`)
    },
    book: async (appointmentData: ReturnType<(typeof helpers)['appointments']['formatSchemaForRequest']>) => {
      return await postRequest<Appointment>(`api/appointment`, JSON.stringify(appointmentData))
    },
    save: async (appointmentData: ReturnType<(typeof helpers)['appointments']['formatSchemaForRequest']>) => {
      return await putRequest<Appointment>(`api/appointment/${appointmentData.id}`, JSON.stringify(appointmentData))
    },
    delete: async (id: number | string) => {
      return await deleteRequest<true>(`api/appointment/${id}`)
    },
  }

  const patients = {
    list: async () => {
      return await getRequest<Patient[]>('api/patient/list')
    },
    one: async (id: number) => {
      return await getRequest<Patient>(`api/patient/${id}`)
    },
    ids: async (ids: number[]) => {
      return await getRequest<BasicPatient[]>('api/patient/ids', { ids })
    },
    search: async (query: string[]) => {
      return await getRequest<BasicPatient[]>('api/patient/search', { query })
    },
    save: async (id: number, patientData: string) => {
      return await putRequest<Patient>(`api/patient/${id}`, patientData)
    },
    vaccineHistory: async (id: number) => {
      return await getRequest<BasicNotifications>(`api/notification/patient/${id}/history`)
    },
    sendRegistration: async (id: number) => {
      return await getRequest<BasicNotifications>(`api/notification/patient/${id}/registration`)
    },
    delete: async (patientId: number | string) => {
      return await deleteRequest(`api/patient/${patientId}`)
    },
  }

  const documents = {
    upload: async (formData: FormData) => {
      return await postRequest<{
        assetId: number
        fileName: string
        conflict?: string
      }>('api/document', formData, {}, false)
    },
    delete: async (assetId: string | number) => {
      return await deleteRequest<true>(`api/document/${assetId}`)
    },
  }

  const session = {
    poll: async (dontExtendSession?: boolean) => {
      let endpoint = `api/auth`
      if (dontExtendSession) {
        endpoint += `?dontExtendSession=${Number(dontExtendSession)}`
      }
      return await getRequest<User>(endpoint)
    },
  }

  return {
    data,
    roomAvailability,
    nurseAvailability,
    appointments,
    patients,
    documents,
    session,
  }
}

export default useApi
