import React, { useEffect } from 'react'
import { Offcanvas, Row, Col, CloseButton } from 'react-bootstrap'
import { RootState } from 'redux/store'
import { useDispatch, useSelector } from 'react-redux'
import { closeNewAppointment, setNewAppointmentDate } from 'redux/stateSlice'
import { addLoadedAppointment } from 'redux/calendarSlice'
import { Formik, FormikHelpers } from 'formik'
import useApi from 'hooks/useApi'
import useToast from 'hooks/useToast'
import AvailabilityGrid from 'components/AvailabilityGrid'
import useAvailability from 'hooks/useAvailability'
import useHelpers from 'hooks/useHelpers'
import AppointmentSummary from 'components/AppointmentSummary'
import AppointmentPatients from 'components/AppointmentPatients'
import appointmentEditSchema from 'schemas/appointment-edit-schema'

/**
 * Edit appointments offcanvas
 */
const NewAppointment: React.FC = () => {
  // State
  const { open, newAppointmentDate } = useSelector((state: RootState) => ({
    open: state.state.newAppointmentOpen,
    newAppointmentDate: state.state.newAppointmentDate as string,
  }))

  const dispatch = useDispatch()
  const api = useApi()
  const toast = useToast()
  const helpers = useHelpers()

  const { isCalculating, timeSlots, availability, loadAvailability } = useAvailability(newAppointmentDate)

  const initialValues = {
    title: 'New appointment',
    startDate: newAppointmentDate,
    endDate: '',
    roomId: '1',
    statusId: '1',
    typeId: '3',
    existingPatients: [],
    newPatients: [],
    notes: [],
  }

  // TODO: reuse this on new appointments offcanvas
  const handleSubmit = async (values: typeof initialValues, formikHelpers: FormikHelpers<typeof initialValues>) => {
    const { setSubmitting, resetForm } = formikHelpers

    if (values) {
      const submission = await api.appointments.book(helpers.appointments.formatSchemaForRequest(values))
      if (submission.errors) {
        toast.error({
          title: 'Error booking appointment',
          text: submission.errors[0].body,
        })
        return
      }
      toast.success({
        title: 'Appointments',
        text: `Successfully booked a new appointment for ${new Date(values.startDate).toDateString()}`,
        timestamp: true,
      })
      // 1. Reset appointment info
      resetForm({ values: { ...initialValues } })
      dispatch(closeNewAppointment())
      // 2. Update calendar appointments with new Appointment
      if (helpers.appointments.isInLoadedRange(values.startDate, values.endDate)) {
        dispatch(addLoadedAppointment(submission.data))
      }
      setSubmitting(false)
    }
  }

  // Fetch availability when the user opens the offcanvas
  useEffect(() => {
    if (open && newAppointmentDate) {
      loadAvailability()
    }
  }, [open, newAppointmentDate])

  return (
    <Offcanvas
      show={open}
      onHide={() => {
        dispatch(closeNewAppointment())
      }}
      onExited={() => dispatch(setNewAppointmentDate(null))}
      placement="end"
      backdropClassName="new-appointment-backdrop"
      className="new-appointment-offcanvas"
    >
      <Offcanvas.Header className="border-bottom border-2">
        <h3 className="mb-0">New Appointment</h3>
        <CloseButton onClick={() => dispatch(closeNewAppointment())} />
      </Offcanvas.Header>
      <Offcanvas.Body>
        <Formik
          initialValues={{ ...initialValues }}
          onSubmit={handleSubmit}
          validationSchema={appointmentEditSchema}
          validateOnMount={true}
          enableReinitialize={true}
        >
          {() => {
            return (
              <>
                <Row>
                  <Col xs={12}>
                    <AppointmentPatients />
                  </Col>
                </Row>
                <hr className="my-5" />
                <Row>
                  <AvailabilityGrid
                    timeSlots={timeSlots}
                    availability={availability}
                    isCalculating={isCalculating}
                    onDateChange={val => dispatch(setNewAppointmentDate(val))}
                    alwaysActive
                  />
                </Row>
                <hr className="my-5" />
                <Row>
                  <Col>
                    <AppointmentSummary />
                  </Col>
                </Row>
              </>
            )
          }}
        </Formik>
      </Offcanvas.Body>
    </Offcanvas>
  )
}

export default NewAppointment
