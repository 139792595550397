import moment from 'moment'
import { Form, Table, Spinner, Button } from 'react-bootstrap'
import { Clock } from './Icons'
import { useFormikContext } from 'formik'
import { AvailabilityRoom } from 'hooks/useAvailability'
import { AppointmentEditSchemaType } from 'schemas/appointment-edit-schema'

interface BaseProps {
  isCalculating: boolean
  timeSlots: Date[]
  availability: AvailabilityRoom[]
  onDateChange?: (value: string) => void
}

interface DependentFinderProps extends BaseProps {
  isEditingDate: boolean
  setIsEditingDate: React.Dispatch<React.SetStateAction<boolean>>
  onCancel: () => void
  alwaysActive?: undefined
}

interface ActiveFinderProps extends BaseProps {
  isEditingDate?: undefined
  setIsEditingDate?: undefined
  onCancel?: undefined
  alwaysActive: boolean
}

// TODO: type onSelect
const AvailabilityGrid: React.FC<DependentFinderProps | ActiveFinderProps> = ({
  isCalculating,
  isEditingDate,
  onCancel,
  setIsEditingDate,
  timeSlots,
  availability,
  onDateChange,
  alwaysActive,
}) => {
  const { values, setFieldValue, setFieldTouched } = useFormikContext<AppointmentEditSchemaType>()

  return (
    <div>
      <div className="d-flex gap-4 align-items-center mb-4">
        <h4 className="mb-0 text-nowrap">Date and Time</h4>
        <Form.Control
          type="date"
          style={{ maxWidth: 300 }}
          disabled={isCalculating || (setIsEditingDate !== undefined && !isEditingDate)}
          value={moment(values.startDate).format('YYYY-MM-DD')}
          name="startDate"
          // min={new Date().toISOString().split('T')[0]}
          onChange={e => {
            if (new Date(e.target.value).getDay() === 0) {
              e.preventDefault()
              return
            }
            setFieldValue('roomId', '')
            setFieldValue('endDate', '')
            setFieldValue('startDate', e.target.value)
            onDateChange?.(e.target.value)
          }}
        />
        {!alwaysActive ? (
          !isEditingDate ? (
            <Button onClick={() => setIsEditingDate && setIsEditingDate(true)}>Edit</Button>
          ) : (
            <Button
              onClick={() => {
                setIsEditingDate && setIsEditingDate(false)
                onCancel && onCancel()
              }}
            >
              Cancel
            </Button>
          )
        ) : (
          ''
        )}
      </div>
      {isEditingDate || alwaysActive ? (
        <div className="position-relative">
          {isCalculating ? (
            <span className="my-4">
              <Spinner size="sm" /> Loading available appointments...
            </span>
          ) : availability.length ? (
            <>
              <p className="mt-4 mb-5">
                Showing available appointments for{'  '}
                {new Date(values.startDate).toLocaleDateString('en-gb', {
                  dateStyle: 'full',
                })}
              </p>
              <Table size="sm" bordered hover className="availability-table">
                <thead className="bg-secondary-accent">
                  <tr>
                    <th>Time</th>
                    {availability.map(room => (
                      <th key={room.roomId}>{room.roomTitle}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {timeSlots.map(timeSlot => {
                    return (
                      <tr key={timeSlot.toTimeString()}>
                        <td>
                          <div className="d-flex gap-2 align-items-center">
                            <Clock />
                            {timeSlot.toLocaleTimeString('en-gb', {
                              timeStyle: 'short',
                            })}
                          </div>
                        </td>
                        {availability.map(room => {
                          const roomSlot = room.slots.find(
                            roomSlot => roomSlot.date.toISOString() === timeSlot.toISOString()
                          )
                          return (
                            <td key={timeSlot.toTimeString() + room.roomId}>
                              {roomSlot ? (
                                <>
                                  {[15, 30, 45, 60].map(duration => {
                                    const endDate = new Date(
                                      new Date(roomSlot.date).setMinutes(roomSlot.date.getMinutes() + duration)
                                    ).toISOString()
                                    return (
                                      <Form.Check
                                        inline
                                        key={duration}
                                        type="radio"
                                        label={duration === 60 ? '1 hour' : `${duration} mins`}
                                        value={duration}
                                        id={`${room.roomId}:${roomSlot.date.toISOString()}:${duration}`}
                                        disabled={!roomSlot.durations.includes(duration)}
                                        checked={
                                          values.startDate === roomSlot.date.toISOString() &&
                                          values.endDate === endDate &&
                                          values.roomId === room.roomId.toString()
                                        }
                                        name={`${room.roomId}:${roomSlot.date.toISOString()}`}
                                        onChange={e => {
                                          setFieldValue('roomId', room.roomId.toString())
                                          // TODO: force validation here
                                          // This setTimeout is needed due to a bug in formik
                                          // @see https://github.com/jaredpalmer/formik/issues/2059
                                          setTimeout(() => setFieldTouched('roomId', true))
                                          setFieldValue('startDate', roomSlot.date.toISOString())
                                          setFieldValue('endDate', endDate)
                                        }}
                                      />
                                    )
                                  })}
                                </>
                              ) : (
                                ''
                              )}
                            </td>
                          )
                        })}
                      </tr>
                    )
                  })}
                </tbody>
              </Table>
            </>
          ) : (
            ''
          )}
        </div>
      ) : (
        ''
      )}
    </div>
  )
}

export default AvailabilityGrid
