import { Container, Navbar, Dropdown, Button } from 'react-bootstrap'
import { useState, useEffect, useRef } from 'react'
import NewAppointmentButton from 'components/NewAppointmentButton'
import { setDisplayingPatientId } from 'redux/stateSlice'
import { useDispatch } from 'react-redux'
import LogoutButton from 'components/LogoutButton'
import ClientSearch from 'components/ClientSearch'
import { ReactComponent as LogoText } from 'assets/svg/logo-text-only.svg'
import { ReactComponent as LogoPlus } from 'assets/svg/logo-plus.svg'
import useAuth from 'hooks/useAuth'

const NavigationBar = () => {
  const dispatch = useDispatch()
  const { user } = useAuth()
  const navbarRef = useRef<any>(null)
  const [navbarHeight, setNavbarHeight] = useState(0)

  const [name] = useState(() => {
    let _name = user?.firstName || user?.friendlyName
    if (user?.lastName) {
      _name += `+${user.lastName}`
    }
    return _name
  })

  // ... Set the header height custom property for use in child elements
  useEffect(() => {
    const updateNavbarHeight = () => {
      if (navbarRef.current && navbarRef.current.offsetHeight !== navbarHeight) {
        setNavbarHeight(navbarRef.current.offsetHeight)
      }
    }
    updateNavbarHeight()
    window.removeEventListener('resize', updateNavbarHeight)
    window.addEventListener('resize', updateNavbarHeight)
    return () => window.removeEventListener('resize', updateNavbarHeight)
  }, [Object.entries(navbarRef)])

  return (
    <Navbar
      bg="secondary"
      variant="light"
      expand="lg"
      ref={navbarRef}
      style={{ ['--navbar-height' as string]: `${navbarHeight.toString()}px` }}
    >
      <Container fluid className="d-flex align-items-center">
        <div className="d-flex flex-nowrap">
          <div className="navbar-brand d-flex align-items-center justify-content-start gap-3 me-5 pe-4">
            <LogoPlus />
            <LogoText style={{ height: '25px' }} />
          </div>

          {/* <Nav className="d-flex flex-row flex-nowrap gap-4 my-2 my-lg-0">
            <Nav.Link as={Link} to="calendar" active={location.pathname.endsWith('/calendar')}>
              CALENDAR
            </Nav.Link>
            <Nav.Link as={Link} to="patients" active={location.pathname.endsWith('/patients')}>
              PATIENTS
            </Nav.Link>
          </Nav> */}
        </div>

        <div className="d-flex align-items-center gap-4">
          <ClientSearch
            placeholder="Search patients"
            aria-label="Search"
            onResultClick={patient => {
              dispatch(setDisplayingPatientId(patient.id))
            }}
          />

          <NewAppointmentButton>
            <Button>NEW APPOINTMENT</Button>
          </NewAppointmentButton>

          <Dropdown align={'end'} className="navbar-avatar">
            <Dropdown.Toggle as={'a'} variant="transparent" id="account-dropdown" className="">
              <img
                className="rounded-circle"
                src={`https://ui-avatars.com/api/?name=${name}`}
                alt="Placeholder"
                style={{ width: '50px', height: '50px' }}
              />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <LogoutButton>
                <Dropdown.Item>Log Out</Dropdown.Item>
              </LogoutButton>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </Container>
    </Navbar>
  )
}
export default NavigationBar
