import { createSlice } from '@reduxjs/toolkit'
import { PayloadAction } from '@reduxjs/toolkit'
import { ToastProps } from 'components/CustomToast'
import { getNextFreeSlotFromDate } from 'helpers/date'

interface InitialState {
  toasts: (ToastProps['data'] | null)[]
  newAppointmentOpen: boolean
  newAppointmentDate: string | null
  editAppointmentId: number | null
  displayingPatientId: number | null,
  activePatientIds: number[]
  activeTypes: number[]
  activeStatuses: number[]
}

const initialState: InitialState = {
  toasts: [],
  newAppointmentOpen: false,
  newAppointmentDate: null,
  editAppointmentId: null,
  displayingPatientId: null,
  activePatientIds: [],
  activeTypes: [1, 2, 3, 4, 5, 6],
  activeStatuses: [1, 2],
}

// Re-used reducers
const _addActivePatient = (state: InitialState, action: PayloadAction<number | null>) => {
  if (!action.payload) return

  if (!state.activePatientIds.includes(action.payload)) {
    state.activePatientIds.push(action.payload)
  }
}

const stateSlice = createSlice({
  name: 'appointments',
  initialState: initialState,
  reducers: {
    notify: (state, action: PayloadAction<ToastProps['data']>) => {
      state.toasts = [...state.toasts, action.payload]
    },
    dismissToast: (state, action: PayloadAction<number>) => {
      const toastKey = action.payload
      let newToasts = [...state.toasts]
      newToasts[toastKey] = null
      state.toasts = newToasts
    },

    setDisplayingPatientId: (state, action: PayloadAction<number | null>) => {
      _addActivePatient(state, action)
      state.displayingPatientId = action.payload
    },
    setNewAppointmentDate: (state, action: PayloadAction<string | null>) => {
      state.newAppointmentDate = action.payload
    },
    setEditAppointmentId: (state, action: PayloadAction<number | null>) => {
      state.editAppointmentId = action.payload
    },
    openNewAppointment: state => {
      if (!state.newAppointmentDate) {
        state.newAppointmentDate = getNextFreeSlotFromDate(new Date()).toISOString()
      }
      state.newAppointmentOpen = true
    },
    closeNewAppointment: state => {
      state.newAppointmentOpen = false
    },
    toggleNewAppointment: state => {
      if (!state.newAppointmentDate) {
        state.newAppointmentDate = getNextFreeSlotFromDate(new Date()).toISOString()
      }
      state.newAppointmentOpen = !state.newAppointmentOpen
    },
    toggleTypeFilter: (state, action: PayloadAction<number>) => {
      if (state.activeTypes.includes(action.payload)) {
        state.activeTypes.splice(state.activeTypes.indexOf(action.payload), 1)
      } else state.activeTypes.push(action.payload)
    },
    toggleStatusFilter: (state, action: PayloadAction<number>) => {
      if (state.activeStatuses.includes(action.payload)) {
        state.activeStatuses.splice(state.activeStatuses.indexOf(action.payload), 1)
      } else state.activeStatuses.push(action.payload)
    },
    addActivePatient: _addActivePatient,
    removeActivePatient: (state, action: PayloadAction<number>) => {
      state.activePatientIds = state.activePatientIds.filter((id) => id !== action.payload)
    },
    setAllActiveTypes: (state, action: PayloadAction<number[]>) => {
      state.activeTypes = action.payload
    },
    setAllActiveStatuses: (state, action: PayloadAction<number[]>) => {
      state.activeStatuses = action.payload
    },
    unsetAllActiveTypes: state => {
      state.activeTypes = []
    },
    unsetAllActiveStatuses: state => {
      state.activeStatuses = []
    },
  },
})

export const {
  notify,
  dismissToast,
  setNewAppointmentDate,
  setDisplayingPatientId,
  setEditAppointmentId,
  openNewAppointment,
  closeNewAppointment,
  toggleNewAppointment,
  toggleTypeFilter,
  toggleStatusFilter,
  addActivePatient,
  removeActivePatient,
  setAllActiveTypes,
  setAllActiveStatuses,
  unsetAllActiveTypes,
  unsetAllActiveStatuses,
} = stateSlice.actions
export default stateSlice.reducer
