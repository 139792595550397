import moment from 'moment'

/**
 * Finds the closest free time slot given a date object, rounded to the nearest 15 minutes (:00, :15, :30, :45).
 */
export const getNextFreeSlotFromDate = (date: Date) => {
  const minutes = 15
  const ms = 1000 * 60 * minutes
  return moment(new Date(Math.ceil(date.getTime() / ms) * ms))
    .utc()
    .toDate()
}

/**
 * Adds 15 minutes onto a date
 */
export const add15MinsToDate = (date: Date) => {
  return new Date(date.getTime() + 15 * 60000)
}

/**
 * Adds x minutes onto a date
 */
export const addMinsToDate = (date: Date, mins: number) => {
  return new Date(date.getTime() + mins * 60000)
}

/**
 * Gets a random date between two dates, with a date time window
 */
export const getRandomDateBetween = (start: string, end: string, startHour: number, endHour: number) => {
  const startDate = new Date(start)
  const endDate = new Date(end)

  var date = new Date(+startDate + Math.random() * (+endDate - +startDate))
  var hour = (startHour + Math.random() * (endHour - startHour)) | 0
  date.setHours(hour)

  return date
}

/**
 * Gets the duration of a date range in minutes
 * Dates must be in order
 */
export const getMinutesBetween = (start: Date, end: Date) => {
  return Math.round((+end - +start) / 60000)
}

/**
 * Gets the difference of a date range in hours
 */
export const getHoursBetween = (start: Date, end: Date) => {
  return Math.abs(start.getTime() - end.getTime()) / 3600000
}

/**
 * Format an ISO Date string to 'yyyy-MM-DD' for use with native HTML date inputs
 */
export const toDateInputString = (date: Date) => {
  const dateElements = date.toLocaleDateString().split('/')
  return `${dateElements[2]}-${dateElements[1]}-${dateElements[0]}`
}
