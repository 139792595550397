import React from 'react'
import { Card, Button, Table, Form, Spinner } from 'react-bootstrap'
import TextArea from './TextArea'
import { useFormikContext, Field } from 'formik'
import { getMinutesBetween } from 'helpers/date'
import useData from 'hooks/useData'
import { Tick, Cross } from './Icons'
import { AppointmentEditSchemaType } from 'schemas/appointment-edit-schema'

interface SummaryProps {
  appointmentId?: number
  handleDelete?: () => void
}

const AppointmentSummary: React.FC<SummaryProps> = ({ appointmentId, handleDelete }) => {
  const data = useData()
  const { values, errors, isSubmitting, isValid, dirty, handleSubmit } = useFormikContext<AppointmentEditSchemaType>()

  const { appointmentTypes, appointmentStatuses } = {
    appointmentTypes: data.appointmentTypes.all(),
    appointmentStatuses: data.appointmentStatuses.all(),
  }

  return (
    <Card>
      <Card.Header>
        <h5>Appointment Summary</h5>
      </Card.Header>
      <Card.Body>
        <Table>
          <tbody>
            {/* <tr>
              <td className="th fw-bold ps-0">Title</td>
              <td>
                <Field as={Form.Control} name="title" />
              </td>
            </tr> */}
            <tr>
              <td className="th fw-bold ps-0">Patients</td>
              <td>
                <ul className="list-unstyled">
                  {[...values.existingPatients].map((patient, index) => (
                    <React.Fragment key={index}>
                      {patient.firstName || patient.lastName ? (
                        <li key={index} className="d-flex align-items-center gap-2">
                          <span className="text-success d-flex align-items-center">
                            <Tick size={20} />
                          </span>
                          {patient.firstName} {patient.lastName}
                        </li>
                      ) : (
                        ''
                      )}
                    </React.Fragment>
                  ))}
                  {[...values.newPatients].map((patient, index) => (
                    <React.Fragment key={index}>
                      {patient.firstName || patient.lastName ? (
                        <li key={index} className="d-flex align-items-center gap-2">
                          <span className="text-danger d-flex align-items-center">
                            <Cross size={20} />
                          </span>
                          {patient.firstName} {patient.lastName}
                        </li>
                      ) : (
                        ''
                      )}
                    </React.Fragment>
                  ))}
                </ul>
              </td>
            </tr>
            <tr>
              <td className="th fw-bold ps-0 pe-4">Date</td>
              <td style={{ width: '100%' }}>
                {values.startDate
                  ? new Date(values.startDate).toLocaleDateString('en-gb', {
                      weekday: 'long',
                      day: '2-digit',
                      month: 'long',
                      year: 'numeric',
                    })
                  : ''}
              </td>
            </tr>
            <tr>
              <td className="th fw-bold ps-0 pe-4">Time</td>
              <td style={{ width: '100%' }}>
                {values.startDate && values.endDate ? (
                  <>
                    {new Date(values.startDate).toLocaleTimeString('en-gb', {
                      hour: '2-digit',
                      minute: '2-digit',
                    })}{' '}
                    -{' '}
                    {new Date(values.endDate).toLocaleTimeString('en-gb', {
                      hour: '2-digit',
                      minute: '2-digit',
                    })}
                  </>
                ) : (
                  ''
                )}
              </td>
            </tr>
            <tr>
              <td className="th fw-bold ps-0 pe-4">Room</td>
              <td>{values.endDate ? data.rooms.one(values.roomId)?.title || values.roomId : ''}</td>
            </tr>

            <tr>
              <td className="th fw-bold ps-0 pe-4">Duration</td>
              <td>
                {/* Self executing function to calculate duration on the fly here */}
                {(() => {
                  const duration =
                    values.startDate && values.endDate
                      ? getMinutesBetween(new Date(values.startDate), new Date(values.endDate)).toString()
                      : ''
                  return duration !== '' ? <>{duration === '60' ? '1 hour' : `${duration} minutes`}</> : ''
                })()}
              </td>
            </tr>
            <tr>
              <td className="th fw-bold ps-0 pe-4">Type</td>
              <td>
                <Field as={Form.Select} name="typeId">
                  {appointmentTypes.map(type => (
                    <option key={type.id} value={type.id}>
                      {type.title}
                    </option>
                  ))}
                </Field>
              </td>
            </tr>
            <tr>
              <td className="th fw-bold ps-0 pe-4">Status</td>
              <td>
                <Field as={Form.Select} name="statusId">
                  {appointmentStatuses.map(status => (
                    <option key={status.id} value={status.id}>
                      {status.title}
                    </option>
                  ))}
                </Field>
              </td>
            </tr>
            <tr>
              <td className="th fw-bold ps-0 pe-4">Notes</td>
              <td>
                <Field as={TextArea} rows={5} name="notes[0].note" />
              </td>
            </tr>
          </tbody>
        </Table>
        {/* FORM ERRORS */}
        {process.env.NODE_ENV === 'development' && Object.keys(errors).length ? (
          <>
            {/* <hr /> */}
            <pre
              style={{
                fontSize: '0.8em',
                maxHeight: '200px',
                border: '1px solid red',
                borderRadius: '6px',
                padding: '0.5rem',
              }}
            >
              <code>{JSON.stringify(errors, null, 2)}</code>
            </pre>
          </>
        ) : (
          ''
        )}
        <div className="d-flex w-100 justify-content-between align-items-center mt-4">
          <Button
            disabled={isSubmitting || !isValid || !dirty}
            onClick={() => handleSubmit()}
            variant="danger"
            className={`text-white ${isSubmitting ? 'loading' : ''}`}
          >
            {isSubmitting ? (
              <>
                <span>Loading...</span>
                <Spinner size="sm" className="ms-2" />
              </>
            ) : (
              'SAVE'
            )}
          </Button>
          {appointmentId ? (
            <Button
              variant="danger"
              className="text-white"
              onClick={() => {
                if (handleDelete !== undefined) {
                  handleDelete()
                }
              }}
            >
              DELETE
            </Button>
          ) : (
            ''
          )}
        </div>
      </Card.Body>
    </Card>
  )
}

export default AppointmentSummary
