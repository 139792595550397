import { useSelector } from 'react-redux'
import { RootState } from 'redux/store'
import useAuth from './useAuth'
import useData from './useData'
import { AppointmentEditSchemaType } from 'schemas/appointment-edit-schema'

const useHelpers = () => {
  const data = useData()
  const { user } = useAuth()

  const { loadedStart, loadedEnd, roomAvailability, nurseAvailability } = useSelector((state: RootState) => ({
    loadedStart: new Date(state.calendar.loadedDateRange.start),
    loadedEnd: new Date(state.calendar.loadedDateRange.end),
    roomAvailability: state.calendar.roomAvailability,
    nurseAvailability: state.calendar.nurseAvailability,
  }))

  const { nurses } = useData()

  const _roomAvailability = {
    hasSecondRoomAvailable: (date: Date) => {
      const searchDate = date.toDateString()
      const availabilityItem = roomAvailability.find(
        availabilityDate => new Date(availabilityDate.date).toDateString() === searchDate
      )
      return availabilityItem !== undefined && availabilityItem.roomIds.length === 2
    },
  }

  const _nurseAvailability = {
    getActiveNurses: (date: Date) => {
      const searchDate = date.toDateString()
      const availabilityItem = nurseAvailability.find(item => new Date(item.date).toDateString() === searchDate)
      return availabilityItem ? availabilityItem.userIds : []
    },
  }

  const appointments = {
    // Check if a date range is inside the loaded date range
    // Useful for checking whether we need to update the currently loaded appointments
    // After updating information on the backend
    isInLoadedRange: (start: string, end: string) => {
      return new Date(start) >= loadedStart && new Date(end) <= loadedEnd
    },
    // Convert the default appointment structure to form values used in
    // - NewAppointment.tsx
    // - EditAppointments.tsx
    appointmentToFormValues: (data: Appointment): AppointmentEditSchemaType => {
      const existingPatients = [...data.patients].map(patient => ({
        id: patient.id.toString(),
        firstName: patient.firstName,
        lastName: patient.lastName,
        dob: patient.dob,
        email: patient.email,
        telephone: patient.telephone,
        mobile: patient.mobile,
      }))

      return {
        id: data.id.toString(),
        startDate: new Date(data.startDate).toISOString(),
        endDate: new Date(data.endDate).toISOString(),
        title: data.title,
        existingPatients: existingPatients,
        newPatients: [],
        typeId: data.type.id.toString(),
        statusId: data.status.id.toString(),
        roomId: data.room.id.toString(),
        notes: data.notes,
      }
    },

    formatSchemaForRequest: (values: AppointmentEditSchemaType) => {
      const note = values.notes.length ? values.notes[0].note : ''
      return {
        id: values.id,
        ownerId: user ? parseInt(user.id.toString()) : '',
        title: values.title,
        startDate: new Date(values.startDate).toISOString(),
        endDate: new Date(values.endDate).toISOString(),
        roomId: parseInt(values.roomId),
        typeId: parseInt(values.typeId),
        statusId: parseInt(values.statusId),
        patients: [...values.existingPatients.map(patient => ({ id: patient.id.toString() })), ...values.newPatients],
        notes: [{ authorId: user?.id as number, note: note }],
      }
    },
  }

  const _data = {
    getVaccineSubTypes: (vaccineTypeId: number) => {
      const _type = data.vaccineTypes.one(vaccineTypeId)
      return _type?.drugs || []
    },
  }

  const _nurses = {
    isNurse: (id: number) => {
      return (
        nurses
          .all()
          .map(nurse => nurse.id)
          .indexOf(id) >= 0
      )
    },
  }

  return {
    roomAvailability: _roomAvailability,
    nurseAvailability: _nurseAvailability,
    data: _data,
    nurses: _nurses,
    appointments,
  }
}

export default useHelpers
