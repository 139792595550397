import React from 'react'
import { useDispatch } from 'react-redux'
import { openNewAppointment } from 'redux/stateSlice'

const NewAppointmentButton = ({ children }: { children: React.ReactNode }) => {
  const dispatch = useDispatch()

  const handleClick = () => {
    dispatch(openNewAppointment())
  }

  return React.cloneElement(children as React.ReactElement<any>, {
    onClick: handleClick,
  })
}

export default NewAppointmentButton
